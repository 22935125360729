<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="monthlyExcerptList"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Reimbursement Excerpt
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
const formTitle = 'Reimbursement Excerpt'

export default {
  components: {
    KitsPanel: () =>
      import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report',
          disabled: false,
          to: '/management/management_kits',
        },
        {
          text: formTitle,
          disabled: true,
        },
      ],
      monthlyExcerptList: [
        {
          icon: 'mdi-cash-refund',
          path: '/management/family_subline_reimburse',
          title: 'ONEfamily Reimbursement',
          desc: 'ONEfamily Reimbursement (Supplementary line) report.',
        },
        {
          icon: 'mdi-cash-refund',
          path: '/management/october_campaign_reimburse',
          title: 'October Free Sim Reimbursement',
          desc: 'October Free Sim reimbursement report.',
        },
        {
          icon: 'mdi-cash-refund',
          path: '/management/basic_pin_mysiswa_reimburse',
          title: 'Basic Pin (MYSISWA) Reimbursement',
          desc: 'Basic Pin (MySiswa) reimbursement report.',
        },
      ],
      clearance: '',
      code: 0,
      height: 'auto',
    }
  },
}
</script>
